export interface MobileSearchLoaderProps {
  duration?: number;
  className?: string;
}
export const MobileSearchLoader = ({
  className,
  duration = 1
}: MobileSearchLoaderProps) => <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="MobileSearchLoader" data-sentry-source-file="MobileSearchLoader.tsx">
    <rect x="0.5" width="5" height="21" data-sentry-element="rect" data-sentry-source-file="MobileSearchLoader.tsx">
      <animate attributeName="fill" values="#323234; #8F8F93; #646468; #4B4B4E; #323234" dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="MobileSearchLoader.tsx" />
    </rect>
    <rect x="9.5" width="5" height="21" data-sentry-element="rect" data-sentry-source-file="MobileSearchLoader.tsx">
      <animate attributeName="fill" values="#4B4B4E; #323234; #8F8F93; #646468; #4B4B4E" dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="MobileSearchLoader.tsx" />
    </rect>
    <rect x="18.5" width="5" height="21" data-sentry-element="rect" data-sentry-source-file="MobileSearchLoader.tsx">
      <animate attributeName="fill" values="#646468; #4B4B4E; #323234; #8F8F93; #646468" dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="MobileSearchLoader.tsx" />
    </rect>
    <rect x="27.5" width="5" height="21" data-sentry-element="rect" data-sentry-source-file="MobileSearchLoader.tsx">
      <animate attributeName="fill" values="#8F8F93; #646468; #4B4B4E; #323234; #8F8F93" dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="MobileSearchLoader.tsx" />
    </rect>
  </svg>;