export interface AnimatedLoaderProps {
  shineWidth?: number;
  duration?: number;
  className?: string;
}
export const AnimatedLoader = ({
  shineWidth = 0.1,
  duration = 1,
  className
}: AnimatedLoaderProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 338 98" width={338} height={98} fill="none" className={className} data-sentry-element="svg" data-sentry-component="AnimatedLoader" data-sentry-source-file="AnimatedLoader.tsx">
    <path d="M3.40271 48.5559L3.40271 7.53995L2.60181 1L39.6837 1C43.261 1 46.1176 1.4037 48.2534 2.21111C50.4425 2.96468 52.071 4.0143 53.1389 5.35997C54.2601 6.65181 54.9809 8.18587 55.3013 9.96216C55.6751 11.6846 55.8619 13.5147 55.8619 15.4525V21.831C55.8619 24.4146 55.4348 26.8099 54.5805 29.0168C53.7796 31.1699 52.2579 32.9193 50.0153 34.2649C47.8262 35.5568 44.6493 36.2027 40.4846 36.2027H34.9583C31.5945 36.2027 28.4443 36.122 25.5077 35.9605C22.6244 35.799 20.462 35.6644 19.0203 35.5568V48.5559H3.40271ZM19.0203 25.222H35.5991C37.3611 25.222 38.5891 24.8722 39.2832 24.1724C39.9773 23.4727 40.3244 22.3423 40.3244 20.7813V16.6636C40.3244 15.1026 40.004 13.9722 39.3633 13.2725C38.7226 12.5189 37.4945 12.1421 35.6792 12.1421L19.0203 12.1421V25.222Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M67.0496 48.5559V1.08074L82.4269 1.08074V48.5559H67.0496Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M147.185 49.3633C145.049 49.3633 142.673 49.0404 140.057 48.3945C137.441 47.6947 134.851 46.0261 132.288 43.3886L128.924 39.9975C127.323 38.3827 125.774 37.0639 124.279 36.0412C122.838 35.0185 121.182 34.2649 119.314 33.7805C117.445 33.2422 115.095 32.9731 112.266 32.9731H111.625V48.5559H96.0073V7.53995L95.2064 1L132.288 1C135.705 1 138.455 1.43061 140.538 2.29184C142.673 3.09924 144.302 4.20269 145.423 5.60219C146.598 6.94786 147.399 8.48192 147.826 10.2044C148.253 11.9268 148.466 13.6762 148.466 15.4525V16.9865C148.466 20.2162 147.639 23.1228 145.984 25.7065C144.382 28.2363 141.819 30.0395 138.295 31.1161C139.63 31.9235 140.885 32.7578 142.059 33.619C143.287 34.4264 144.676 35.2876 146.224 36.2027C147.986 37.2254 149.614 37.979 151.109 38.4634C152.658 38.894 153.939 39.1632 154.954 39.2708C155.968 39.3785 156.556 39.4323 156.716 39.4323L154.313 48.5559C153.672 48.7174 152.685 48.8789 151.35 49.0404C150.068 49.2557 148.68 49.3633 147.185 49.3633ZM120.915 22.2347C122.25 22.2347 123.532 22.2077 124.76 22.1539C126.041 22.1001 127.189 22.0193 128.204 21.9117C130.019 21.6964 131.247 21.1312 131.888 20.2162C132.582 19.2473 132.929 18.09 132.929 16.7443V15.8562C132.929 14.672 132.582 13.7569 131.888 13.111C131.247 12.4651 130.046 12.1421 128.284 12.1421H111.625V21.9117C112.96 21.9655 114.428 22.0463 116.03 22.1539C117.632 22.2077 119.26 22.2347 120.915 22.2347Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M158.189 48.5559L181.656 1.08074L202.479 1.08074L225.866 48.5559H208.646L204.722 39.9167H179.413L175.008 48.5559H158.189ZM183.818 28.7746L200.237 28.7746L192.468 11.577H191.667L183.818 28.7746Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M239.218 48.5559V11.9807L220.157 11.9807V1L274.058 1V11.9807H254.756V48.5559H239.218Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M282.058 48.5559V1.08074L333.476 1.08074V12.0614H297.676V18.7628L324.186 18.7628V29.9857L297.676 29.9857V37.4945H332.195L337 47.6678C337 47.8831 336.279 48.0984 334.838 48.3137C333.396 48.4752 330.913 48.5559 327.389 48.5559H282.058Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M11.652 97C8.02126 97 5.35158 96.8385 3.64298 96.5156C1.93439 96.1926 1.08009 95.8158 1.08009 95.3852L5.88552 84.9697C7.11357 85.185 9.64977 85.4003 13.4941 85.6156C17.3384 85.7771 22.3308 85.8579 28.471 85.8579H34.7982C36.6669 85.8579 37.9217 85.5887 38.5624 85.0505C39.2031 84.5122 39.5235 83.7317 39.5235 82.709V81.9016C39.5235 80.6098 39.2031 79.7216 38.5624 79.2372C37.9217 78.7527 36.6669 78.5105 34.7982 78.5105H16.4575C11.0647 78.5105 7.14027 77.5147 4.68416 75.5231C2.22805 73.4777 1 70.1674 1 65.5921L1 62.6855C1 60.3171 1.42715 58.1371 2.28145 56.1455C3.18914 54.1539 4.71086 52.566 6.8466 51.3818C8.98235 50.1438 11.919 49.5248 15.6565 49.5248H42.2466C45.5036 49.5248 48.2 49.7132 50.3357 50.09C52.5248 50.4668 53.6194 50.8705 53.6194 51.3011L51.2167 61.3129C49.7217 61.1514 46.8918 60.9899 42.7271 60.8284C38.6158 60.6131 33.3832 60.5055 27.0294 60.5055L21.2629 60.4247C19.6077 60.4247 18.433 60.6669 17.7389 61.1514C17.0448 61.582 16.671 62.4163 16.6176 63.6543V64.2195C16.6176 65.4575 16.9914 66.3188 17.7389 66.8032C18.4864 67.2876 19.6878 67.5299 21.343 67.5299H38.9629C42.3801 67.5299 45.29 67.8797 47.6927 68.5795C50.0954 69.2792 51.9375 70.5711 53.219 72.455C54.5004 74.2851 55.1411 77.0034 55.1411 80.6098V83.5164C55.1411 87.5534 54.1 90.8099 52.0176 93.286C49.9886 95.762 46.438 97 41.3656 97H11.652Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M77.0778 97L59.9384 49.6056H76.9977L85.0067 76.5727L86.8488 83.5971H87.6497L88.6909 78.9142L96.9402 49.6056H115.361L124.091 78.9142L125.532 83.5971H126.333L128.175 76.5727L135.464 49.6056H152.283L151.482 54.45L134.983 97H116.562L108.073 70.9209L106.471 64.1388H105.67L104.148 70.9209L95.7388 97H77.0778Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M147.318 97L170.784 49.5248H191.608L214.994 97H197.775L193.85 88.3608H168.542L164.137 97H147.318ZM172.947 77.2187H189.365L181.596 60.021H180.795L172.947 77.2187Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <path d="M222.869 97V55.984L222.068 49.4441H259.15C262.728 49.4441 265.584 49.8478 267.72 50.6552C269.909 51.4088 271.538 52.4584 272.605 53.804C273.727 55.0959 274.447 56.6299 274.768 58.4062C275.142 60.1287 275.328 61.9588 275.328 63.8966V70.275C275.328 72.8587 274.901 75.254 274.047 77.4609C273.246 79.614 271.724 81.3633 269.482 82.709C267.293 84.0008 264.116 84.6468 259.951 84.6468L254.425 84.6468C251.061 84.6468 247.911 84.566 244.974 84.4045C242.091 84.2431 239.929 84.1085 238.487 84.0008V97H222.869ZM238.487 73.6661H255.066C256.828 73.6661 258.056 73.3162 258.75 72.6165C259.444 71.9167 259.791 70.7864 259.791 69.2254V65.1077C259.791 63.5467 259.471 62.4163 258.83 61.7166C258.189 60.963 256.961 60.5862 255.146 60.5862H238.487V73.6661Z" stroke="url(#animated_linear_gradient)" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="AnimatedLoader.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="AnimatedLoader.tsx">
      <linearGradient id="animated_linear_gradient" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="AnimatedLoader.tsx">
        <stop offset={-(shineWidth * 3)} stopColor="#34160A" data-sentry-element="stop" data-sentry-source-file="AnimatedLoader.tsx">
          <animate attributeName="offset" values={`${-(shineWidth * 3)};1`} dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="AnimatedLoader.tsx" />
        </stop>
        <stop offset={-(shineWidth * 2)} stopColor="#F24F0C" data-sentry-element="stop" data-sentry-source-file="AnimatedLoader.tsx">
          <animate attributeName="offset" values={`${-(shineWidth * 2)};${1 + shineWidth}`} dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="AnimatedLoader.tsx" />
        </stop>
        <stop offset={-shineWidth} stopColor="#893411" data-sentry-element="stop" data-sentry-source-file="AnimatedLoader.tsx">
          <animate attributeName="offset" values={`${-shineWidth};${1 + shineWidth * 2}`} dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="AnimatedLoader.tsx" />
        </stop>
        <stop offset="0" stopColor="#34160A" data-sentry-element="stop" data-sentry-source-file="AnimatedLoader.tsx">
          <animate attributeName="offset" values={`0;${1 + shineWidth * 3}`} dur={duration + 's'} repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="AnimatedLoader.tsx" />
        </stop>
      </linearGradient>
    </defs>
  </svg>;