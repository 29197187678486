import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { hasAuthParams, useAuth } from 'react-oidc-context'

import {
  useGetSteamProfile,
  useRemoveUserProfile,
} from '@/hooks/query/useGetSteamProfile'
import useLocalStorageReferer from '@/hooks/storage/useLocalStorageReferer'
import useSessionStorageSkinQuery from '@/hooks/storage/useSessionStorageSkinQuery'
import useSesionStorageWasLoginSilently from '@/hooks/storage/useSessionStorageWasLoginSilently'
import { getToken } from '@/utils/getToken'
import { urls } from '@/utils/urls'

export const useAuthRedirect = () => {
  const { wasLoginSilently, setWasLoginSilently } =
    useSesionStorageWasLoginSilently()
  const { setReferer } = useLocalStorageReferer()
  const { skinQuery, removeSkinQuery } = useSessionStorageSkinQuery()
  const auth = useAuth()
  const router = useRouter()
  const { data, isLoading, isFetched } = useGetSteamProfile({
    refetchOnReconnect: false,
    staleTime: Infinity,
    enabled: auth.isAuthenticated,
  })
  const removeUserProfile = useRemoveUserProfile()

  const isLandingOrExchanger =
    router.pathname === urls.landing || router.pathname === urls.exchanger

  // Handle query parameters and store them in localStorage if necessary
  const handleQueryParams = () => {
    const { ref, mhn, mhnc } = router.query
    if (ref && typeof ref === 'string' && isLandingOrExchanger) {
      setReferer(ref)
    }

    if (mhn && mhnc && typeof mhn === 'string' && typeof mhnc === 'string') {
      if (router.pathname === urls.landing) {
        router.replace(`${urls.exchanger}?mhn=${mhn}&mhnc=${mhnc}`, undefined, {
          shallow: true,
        })
      }
    }
  }

  // Handle authentication state and perform silent sign-in if needed
  const handleAuthState = () => {
    if (
      !auth.error &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      if (hasAuthParams()) {
        auth.signinRedirect()
      } else {
        const token = getToken()
        if (!token || wasLoginSilently) {
          removeUserProfile()
          return
        }

        auth
          .signinSilent()
          .then(() => {
            setWasLoginSilently(true)
          })
          .catch((error) => {
            console.error('Silent login failed:', error)
            setWasLoginSilently(true)
            removeUserProfile()
          })
      }
    }
  }

  // Handle redirects based on authentication state and query parameters
  const handleRedirects = () => {
    const authError = auth.error as unknown as Error & { error: string }
    if (auth.isLoading) {
      return
    }

    const queryRedirect = () => {
      const query = new URLSearchParams(
        Object.entries(router.query).map(([key, value]) => [key, String(value)])
      )
      router.replace(`${urls.exchanger}?${query.toString()}`, undefined, {
        shallow: true,
      })
    }

    if (auth.isAuthenticated && isLandingOrExchanger) {
      if (
        router.query.hasOwnProperty('mhn') &&
        router.query.hasOwnProperty('mhnc')
      ) {
        queryRedirect()
      } else if (skinQuery) {
        const query = new URLSearchParams(
          //@ts-ignore
          skinQuery
        )
        router.replace(`${urls.exchanger}?${query.toString()}`, undefined, {
          shallow: true,
        })
        removeSkinQuery()
      } else {
        router.replace(
          `${urls.exchanger}${window.location.search}`,
          undefined,
          {
            shallow: true,
          }
        )
      }
    }

    if (
      auth.isAuthenticated &&
      router.pathname === urls.exchanger &&
      router.query.code !== undefined &&
      !isLoading
    ) {
      router.replace(urls.exchanger, undefined, {
        locale: data ? data.language.toLowerCase() : undefined,
      })
    }

    if (
      !auth.isAuthenticated &&
      authError?.error !== 'PrivateSteamAccountException' &&
      authError?.error !== 'access_denied'
    ) {
      // Don't redirect to landing page if the user is already on the landing or exchanger page
      if (isLandingOrExchanger) return
      router.push(urls.landing)
    }
  }

  // Handle authentication errors and redirect or throw errors as needed
  const handleAuthError = () => {
    const authError = auth.error as unknown as Error & {
      error: string
    }

    if (router.route === urls.landing || !authError) {
      return
    }
    if (authError.error === 'access_denied') {
      throw new Error('userBlocked')
    } else if (authError.error === 'PrivateSteamAccountException') {
      return
    } else if (authError.message === 'No matching state found in storage') {
      router.replace(
        isLandingOrExchanger ? router.pathname : urls.landing,
        undefined,
        {
          shallow: true,
        }
      )
    } else {
      router.push(urls.landing)
    }
  }

  // Remove user if authenticated but token is missing
  const handleUserRemoval = () => {
    if (auth.isAuthenticated && getToken() === null) {
      auth.removeUser()
    }
  }

  // Integrate with Crisp chat if data is available
  const handleCrispIntegration = () => {
    if (data && window.$crisp && isFetched) {
      data.steamId &&
        window.$crisp.push(['set', 'user:nickname', [data.steamId]])
      data.steamAvatarUrl &&
        window.$crisp.push(['set', 'user:avatar', [data.steamAvatarUrl]])
    }
  }

  // Reset wasLoginSilently when user successfully authenticates
  useEffect(() => {
    if (auth.isAuthenticated) {
      setWasLoginSilently(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated])

  // Set up effects to handle various states and actions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleQueryParams, [router.pathname, router.query])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAuthState, [auth, auth.isLoading, wasLoginSilently])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleRedirects, [
    auth.error,
    auth.isAuthenticated,
    auth.isLoading,
    data,
    isLoading,
  ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAuthError, [auth.error])
  useEffect(handleUserRemoval, [auth])
  useEffect(handleCrispIntegration, [data, isFetched])

  // Log messages based on active navigator state
  if (auth.activeNavigator) {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        // eslint-disable-next-line no-console
        console.log('Signing you in...')
      case 'signoutRedirect':
        // eslint-disable-next-line no-console
        console.log('Signing you out...')
    }
  }
}
