import styled from 'styled-components';
import { AnimatedLoader } from './AnimatedLoader';
export const FullPageAnimatedLoader = () => <StyledWrapper data-sentry-element="StyledWrapper" data-sentry-component="FullPageAnimatedLoader" data-sentry-source-file="FullPageAnimatedLoader.tsx">
    <StyledAnimatedLoader data-sentry-element="StyledAnimatedLoader" data-sentry-source-file="FullPageAnimatedLoader.tsx" />
  </StyledWrapper>;
const StyledWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledAnimatedLoader = styled(AnimatedLoader)`
  width: 336px;
  height: 96px;
`;