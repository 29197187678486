import { useSessionStorage } from 'usehooks-ts'

export interface SkinQuery {
  mhn: string
  mhnc: string
}

export const SS_SKIN_QUERY_KEY = 'pirate-swap/skin-query'

const useSessionStorageSkinQuery = () => {
  const [skinQuery, setSkinQuery, removeSkinQuery] =
    useSessionStorage<SkinQuery | null>(SS_SKIN_QUERY_KEY, null)

  return { skinQuery, setSkinQuery, removeSkinQuery }
}

export default useSessionStorageSkinQuery
