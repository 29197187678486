import { useSessionStorage } from 'usehooks-ts'

const SS_WAS_LOGIN_SILENTLY_KEY = 'pirate-swap/was-login-silently'

const useSesionStorageWasLoginSilently = () => {
  const [wasLoginSilently, setWasLoginSilently] = useSessionStorage(
    SS_WAS_LOGIN_SILENTLY_KEY,
    false
  )

  return { wasLoginSilently, setWasLoginSilently }
}

export default useSesionStorageWasLoginSilently
